import DashboardIcon from "@mui/icons-material/Dashboard";
import ChatIcon from "@mui/icons-material/Chat";
import DescriptionIcon from "@mui/icons-material/Description";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";

export const menuItems = [
  {
    text: "Dashboard",
    icon: <DashboardIcon style={{ color: "#1A1A1A" }} />,
    section: "backoffice_dashboard",
    link: "/dashboard",
  },
  {
    text: "Chats",
    icon: <ChatIcon style={{ color: "#1A1A1A" }} />,
    section: "backoffice_chat",
    link: "/chats",
  },
  {
    text: "Documents",
    icon: <DescriptionIcon style={{ color: "#1A1A1A" }} />,
    section: "backoffice_documents",
    link: "/documents",
  },
  {
    text: "Users", // New Users section
    icon: <PeopleIcon style={{ color: "#1A1A1A" }} />,
    section: "backoffice_users",
    link: "/users",
  },
  {
    text: "Settings",
    icon: <SettingsIcon style={{ color: "#1A1A1A" }} />,
    section: "backoffice_config",
    link: "/settings",
  },
];
