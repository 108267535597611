import { styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  TextareaAutosize,
  List,
  Button,
  Typography,
} from "@mui/material";

export const Root = styled(Box)(({ theme, isMobile }) => ({
  width: "100%",
  display: "flex",
  height: "100%",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(isMobile ? 1 : 3),
  margin: 0,
  overflow: "hidden",
  marginTop: "2vh",
}));

export const Sidebar = styled(Box)(({ theme, isSidebarOpen, isMobile }) => {
  let width = isMobile ? "100%" : "20vw";
  let maxWidth = isMobile ? "100%" : "20vw";
  if (!isSidebarOpen) {
    width = "0";
    maxWidth = "0";
  }
  return {
    width: width,
    maxWidth: maxWidth,
    borderRight: `1px solid ${theme.palette.divider}`,
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    flexShrink: 0,
    padding: theme.spacing(isMobile ? 1 : 3),
    height: "calc(95vh - 48px)", // Adjusted height to leave space for the navbar
    transition: "width 0.3s ease-in-out",
  };
});

export const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  height: "calc(95vh - 48px)", // Adjusted height to leave space for the navbar
  padding: theme.spacing(2), // Increased padding for better spacing
  overflowY: "auto",
}));

export const DocumentHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const DocumentInfo = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));

export const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  borderRadius: "8px",
  border: "1px solid #ccc",
  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  fontSize: "1rem",
  lineHeight: "1.5",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.default,
  resize: "none",
  boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
  transition: "border-color 0.3s, box-shadow 0.3s",
  "&:focus": {
    outline: "none",
    borderColor: theme.palette.primary.main,
    boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
  },
}));

export const PaginationControls = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(2),
}));

export const PaginationButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: "20px",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  alignSelf: "flex-end",
  padding: theme.spacing(1, 3),
  borderRadius: "20px",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

export const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "20px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

export const DocumentList = styled(List)(({ theme }) => ({
  maxHeight: "calc(100vh - 250px)",
  overflowY: "auto",
  paddingRight: theme.spacing(1),
}));

export const CenteredButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  "& > *": {
    margin: theme.spacing(2),
  },
}));

export const DialogActionsStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  display: "flex",
  justifyContent: "space-between",
  "& > *": {
    margin: 0,
  },
}));
