// Example utility file for handling authentication related tasks
export const isAuthenticated = () => {
  // Add your authentication logic here
  return !!localStorage.getItem("user");
};

export const login = (token) => {
  localStorage.setItem("user", token);
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const hasAccessToRoute = (section) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const privileges = user ? user.privilege : {};
  // Check if the user has the "view" privilege for the given section
  return privileges[section] && privileges[section].includes("view");
};

export const hasPrivilege = (section, privilege) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user.privilege[section]?.includes(privilege);
};
