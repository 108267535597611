import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated, hasAccessToRoute } from "../../utils/AuthUtils"; // Import necessary utilities

const PrivateRoute = ({ children, section }) => {
  try {
    // Check if the user is authenticated and has access to the specific section
    if (isAuthenticated() && hasAccessToRoute(section)) {
      return children;
    } else {
      console.warn(`Access denied to section: ${section}`);
      return <Navigate to="/" />;
    }
  } catch (error) {
    console.error("Error in PrivateRoute:", error);
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
