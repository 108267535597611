import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Snackbar,
  useMediaQuery,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  Add,
  Edit,
  Delete,
  Save,
  ArrowBack,
  ArrowForward,
  NoteAdd,
  UploadFile,
  DeleteForever,
  Download,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Root,
  Sidebar,
  Content,
  DocumentHeader,
  DocumentInfo,
  StyledTextareaAutosize,
  PaginationControls,
  PaginationButton,
  SaveButton,
  AddButton,
  DocumentList,
  CenteredButtonContainer,
} from "./DocumentsStyles";
import { getData, postData, patchData, deleteData } from "../../services/Api";
import { hasPrivilege } from "../../utils/AuthUtils";

const Documents = () => {
  const { t } = useTranslation("documents");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const { deviceId, documentId } = useParams();
  const [modifiedDocuments, setModifiedDocuments] = useState([]);
  const [loadingDocumentsState, setLoadingDocumentsState] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [openDeviceDialog, setOpenDeviceDialog] = useState(false);
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [openConfirmAllDialog, setOpenConfirmAllDialog] = useState(false);
  const [deviceForm, setDeviceForm] = useState({
    device_id: "",
    markdown_content: "",
    image_path: "",
  });
  const [newDocumentForm, setNewDocumentForm] = useState({
    content: "",
    file: null,
    clear_existing: false,
    returnable_images: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [lastDocumentEvaluatedKey, setLastDocumentEvaluatedKey] =
    useState(null);
  const [loadingDevices, setLoadingDevices] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [savingDocument, setSavingDocument] = useState(false);
  const [loadingNewDocument, setLoadingNewDocument] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [allDocumentsLoaded, setAllDocumentsLoaded] = useState(false);
  const [totalDocumentsLength, setTotalDocumentsLength] = useState(0);
  const [docLimit, setDocLimit] = useState(5);
  const contentRef = useRef(null);
  const [documentToDelete, setDocumentToDelete] = useState(null);

  useEffect(() => {
    fetchDevices();
  }, [page, searchTerm]);

  const handleScroll = () => {
    const bottom =
      contentRef.current.scrollHeight - contentRef.current.scrollTop ===
      contentRef.current.clientHeight;
    if (bottom && lastDocumentEvaluatedKey) {
      setLoadingDocuments(true);
    }
  };

  useEffect(() => {
    const contentNode = contentRef.current;
    if (contentNode) {
      contentNode.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentNode) {
        contentNode.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastDocumentEvaluatedKey, documents]);

  useEffect(() => {
    if (!documentId && selectedDevice) {
      fetchDocuments();
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (deviceId) {
      setSelectedDevice({ device_id: deviceId });
    }
  }, [deviceId]);

  useEffect(() => {
    if (documentId) {
      setDocLimit(parseInt(documentId) + 5);
    }
  }, [documentId]);

  const fetchDevices = async () => {
    setLoadingDevices(true);
    try {
      const encodedLastEvaluatedKey = lastEvaluatedKey
        ? encodeURIComponent(JSON.stringify(lastEvaluatedKey))
        : "";

      const response = await getData(
        `/backoffice/devices?limit=10&last_evaluated_key=${encodedLastEvaluatedKey}&search=${encodeURIComponent(
          searchTerm
        )}`
      );

      const defaultDevice = {
        device_id: "default",
      };

      const updatedDevices = [
        defaultDevice,
        ...response.devices.filter((x) => x.device_id != "default"),
      ];
      setDevices(updatedDevices);
      setLastEvaluatedKey(
        response.last_evaluated_key
          ? JSON.parse(response.last_evaluated_key)
          : null
      );
    } catch (error) {
      console.error(t("error_loading_data"), error);
    } finally {
      setLoadingDevices(false);
    }
  };

  // Download all documents as a ZIP file
  const downloadDocumentsAsZip = async () => {
    try {
      const response = await getData(
        `/backoffice/devices/${selectedDevice.device_id}/documents/download`,
        null,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `device_${selectedDevice.device_id}_documents.txt`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(t("error_downloading_documents"), error);
      showSnackbar(t("error_downloading_documents"), "error");
    }
  };

  const fetchDocuments = async () => {
    if (allDocumentsLoaded) return;
    setLoadingDocuments(true);
    try {
      const response = await getData(
        `/backoffice/devices/${
          selectedDevice.device_id
        }/documents?limit=${docLimit}&last_evaluated_key=${
          lastDocumentEvaluatedKey || ""
        }`
      );
      if (response.documents_length >= documents.length) {
        setDocuments((prevDocuments) => {
          const uniqueDocuments = [
            ...prevDocuments,
            ...response.documents.filter(
              (newDoc) =>
                !prevDocuments.some(
                  (existingDoc) =>
                    existingDoc.document_id === newDoc.document_id
                )
            ),
          ];
          return uniqueDocuments;
        });
        setTotalDocumentsLength(response.documents_length);
        setLastDocumentEvaluatedKey(response.last_evaluated_key || null);
        if (response.last_evaluated_key == null) {
          setAllDocumentsLoaded(true);
        }
        handleScrollToDocument(
          (response.last_evaluated_key || response.documents_length) - 6
        );
      } else {
        setAllDocumentsLoaded(true);
      }
    } catch (error) {
      console.error(t("error_loading_data"), error);
    } finally {
      setLoadingDocuments(false);
    }
  };

  useEffect(() => {
    if (loadingDocuments) {
      fetchDocuments();
    }
  }, [loadingDocuments]);

  useEffect(() => {
    fetchDocuments();
  }, [docLimit]);

  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
    setDocuments([]);
    setAllDocumentsLoaded(false);
    setLastDocumentEvaluatedKey(null);
    setLoadingDocuments(true);

    // Update the URL with the device ID
    navigate(`/documents/${device.device_id}`);

    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  // Update the modified document state when a document is edited
  const handleDocumentChange = (index, value) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].content = value;

      // Add to modified documents if it's not already there
      if (!modifiedDocuments.includes(updatedDocuments[index].document_id)) {
        setModifiedDocuments((prev) => [
          ...prev,
          updatedDocuments[index].document_id,
        ]);
      }

      return updatedDocuments;
    });
  };

  // Save individual document
  const saveDocument = async (document_id) => {
    setLoadingDocumentsState((prev) => [...prev, document_id]); // Start loading for this document
    const documentToSave = documents.find(
      (doc) => doc.document_id === document_id
    );
    try {
      await patchData(
        `/backoffice/devices/${selectedDevice.device_id}/documents/${document_id}`,
        { document: documentToSave }
      );
      // Remove from modifiedDocuments after successful save
      setModifiedDocuments((prev) => prev.filter((id) => id !== document_id));
    } catch (error) {
      console.error(t("error_saving_document"), error);
    } finally {
      setLoadingDocumentsState((prev) =>
        prev.filter((id) => id !== document_id)
      ); // Stop loading for this document
    }
  };

  // Save all modified documents sequentially
  const saveAllDocuments = async () => {
    setSavingDocument(true);
    for (const document_id of modifiedDocuments) {
      await saveDocument(document_id);
    }
    setSavingDocument(false);
  };

  const handleScrollToDocument = async (index) => {
    if (index > parseInt(lastDocumentEvaluatedKey)) {
      setDocLimit(index - parseInt(lastDocumentEvaluatedKey) + 1);
    } else {
      const targetElement = document.getElementById(`document-${index}`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }

    navigate(`/documents/${selectedDevice.device_id}/${index}`);
  };

  const handleDeviceDialogClose = () => {
    setOpenDeviceDialog(false);
    setDeviceForm({ device_id: "", markdown_content: "", image_path: "" });
  };

  const handleDeviceSave = async () => {
    try {
      await postData("/backoffice/devices", deviceForm);
      fetchDevices();
      handleDeviceDialogClose();
      showSnackbar(t("add_device_success"), "success");
    } catch (error) {
      console.error(t("error_saving_device"), error);
      showSnackbar(t("error_saving_device"), "error");
    }
  };
  const handleNewDocumentDialogOpen = (state) => {
    setNewDocumentForm({
      content: "",
      file: null,
      clear_existing: false,
      returnable_images: false,
    });
    setOpenDocumentDialog(state);
  };

  const handleNewDocumentSave = async () => {
    setLoadingNewDocument(true);
    const formData = new FormData();

    if (newDocumentForm.file) {
      formData.append("file", newDocumentForm.file);
    }
    formData.append("content", newDocumentForm.content);
    formData.append(
      "clear_existing",
      newDocumentForm.clear_existing ? "true" : "false"
    );
    formData.append(
      "returnable_images",
      newDocumentForm.returnable_images ? "true" : "false"
    );

    try {
      await postData(
        `/backoffice/devices/${selectedDevice.device_id}/documents`,
        formData,
        true // Pass `true` to indicate it's FormData
      );
      fetchDocuments();
      setOpenDocumentDialog(false);
      showSnackbar(t("add_document_success"), "success");
    } catch (error) {
      console.error(t("error_adding_document"), error);
      showSnackbar(t("error_adding_document"), "error");
    } finally {
      setLoadingNewDocument(false);
    }
  };

  const handleFileChange = (e) => {
    setNewDocumentForm({ ...newDocumentForm, file: e.target.files[0] });
  };

  // Function to delete all documents
  const handleDeleteAllDocuments = async () => {
    try {
      await deleteData(
        `/backoffice/devices/${selectedDevice.device_id}/documents`
      );
      showSnackbar(t("all_documents_removed_success"), "success");
      // Refresh document list after deletion
      fetchDocuments();
    } catch (error) {
      console.error(t("error_removing_all_documents"), error);
      showSnackbar(t("error_removing_all_documents"), "error");
    } finally {
      setOpenConfirmAllDialog(false); // Close confirmation dialog after completion
    }
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Handle delete button click to open confirmation dialog
  const handleOpenConfirmDelete = (document) => {
    setDocumentToDelete(document);
    setOpenConfirmDeleteDialog(true);
  };

  // Confirm delete document action
  const confirmDocumentDelete = async () => {
    setLoadingDocuments(true);
    try {
      await deleteData(
        `/backoffice/devices/${selectedDevice.device_id}/documents/${documentToDelete.document_id}`
      );
      setDocuments((prevDocuments) =>
        prevDocuments.filter(
          (doc) => doc.document_id !== documentToDelete.document_id
        )
      );
      showSnackbar(t("document_removed_success"), "success");
    } catch (error) {
      console.error(t("error_removing_document"), error);
      showSnackbar(t("error_removing_document"), "error");
    } finally {
      setLoadingDocuments(false);
      setOpenConfirmDeleteDialog(false);
    }
  };

  return (
    <Root isMobile={isMobile}>
      {isSidebarOpen && (
        <Sidebar isSidebarOpen={isSidebarOpen} isMobile={isMobile}>
          <Typography variant="h6">{t("devices")}</Typography>
          <TextField
            label={t("search_devices")}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
              setLastEvaluatedKey(null);
            }}
            fullWidth
            margin="dense"
          />
          <DocumentList>
            {loadingDevices ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : (
              devices.map((device) => (
                <ListItem
                  button
                  key={device.device_id}
                  onClick={() => handleDeviceClick(device)}
                >
                  <ListItemText
                    primary={
                      device.device_id === "default"
                        ? t("device_default")
                        : device.device_id
                    }
                  />
                  {hasPrivilege("backoffice_documents", "edit") && (
                    <>
                      <IconButton edge="end" aria-label="edit">
                        <Edit />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete">
                        <Delete />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))
            )}
            <Divider />
            {hasPrivilege("backoffice_documents", "create") && (
              <CenteredButtonContainer>
                <AddButton
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => setOpenDeviceDialog(true)}
                >
                  {t("add_device")}
                </AddButton>
              </CenteredButtonContainer>
            )}
          </DocumentList>
          <PaginationControls>
            <PaginationButton
              onClick={() => setPage((prevPage) => prevPage - 1)}
              disabled={page === 1}
              startIcon={<ArrowBack />}
            >
              {t("previous")}
            </PaginationButton>
            <PaginationButton
              onClick={() => setPage((prevPage) => prevPage + 1)}
              disabled={!lastEvaluatedKey}
              endIcon={<ArrowForward />}
            >
              {t("next")}
            </PaginationButton>
          </PaginationControls>
        </Sidebar>
      )}
      <Content>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {isMobile && (
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              {isSidebarOpen ? <ArrowBack /> : <ArrowForward />}
            </IconButton>
          )}
          <Typography variant="h6">
            {selectedDevice
              ? `${t("documents_for")} ${
                  selectedDevice.device_id === "default"
                    ? t("device_default")
                    : selectedDevice.device_id
                }`
              : t("select_device")}
          </Typography>

          {/* Button to download all documents as ZIP */}
          {selectedDevice && (
            <Button
              variant="contained"
              startIcon={<Download />}
              onClick={downloadDocumentsAsZip}
            >
              {t("download_all_documents")}
            </Button>
          )}
        </Box>
        {selectedDevice && selectedDevice.device_id && (
          <>
            <CenteredButtonContainer>
              {hasPrivilege("backoffice_documents", "create") && (
                <AddButton
                  variant="contained"
                  startIcon={<NoteAdd />}
                  onClick={() => setOpenDocumentDialog(true)}
                >
                  {t("add_document")}
                </AddButton>
              )}
              {hasPrivilege("backoffice_documents", "edit") &&
                isEditing &&
                modifiedDocuments.length > 0 && (
                  <SaveButton
                    onClick={saveAllDocuments}
                    endIcon={
                      savingDocument ? <CircularProgress size={24} /> : <Save />
                    }
                  >
                    {savingDocument ? t("saving") : t("save_all")}
                  </SaveButton>
                )}
              {hasPrivilege("backoffice_documents", "remove") && (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<Delete />}
                  onClick={() => setOpenConfirmAllDialog(true)} // Open confirm dialog before removing all
                  style={{ marginLeft: "10px" }} // Add margin to separate from Add button
                >
                  {t("remove_all_documents")}
                </Button>
              )}
            </CenteredButtonContainer>
            {hasPrivilege("backoffice_documents", "edit") && (
              <Box display="flex" alignItems="center">
                <Typography variant="body1" mr={2}>
                  {isEditing ? t("edit_mode") : t("view_mode")}
                </Typography>
                <Switch
                  checked={isEditing}
                  onChange={() => setIsEditing((prev) => !prev)}
                  color="primary"
                />
              </Box>
            )}

            <Box display="flex">
              <Box
                ref={contentRef}
                flex={1}
                style={{ overflowY: "auto", maxHeight: "75vh" }}
              >
                {documents.length > 0 ? (
                  documents.map((document, index) => (
                    <Box
                      key={document.document_id}
                      id={`document-${index}`}
                      mb={4}
                      p={2}
                      border="1px solid #ddd"
                      borderRadius="8px"
                    >
                      <DocumentHeader>
                        <DocumentInfo variant="subtitle1">
                          {`${t("document")}: ${index + 1}`}
                        </DocumentInfo>

                        {/* Add delete button for each document when editing */}
                        {isEditing && (
                          <IconButton
                            color="error"
                            onClick={() => handleOpenConfirmDelete(document)}
                          >
                            <DeleteForever />
                          </IconButton>
                        )}
                      </DocumentHeader>

                      {isEditing ? (
                        <StyledTextareaAutosize
                          rowsMin={10}
                          value={document.content}
                          onChange={(e) =>
                            handleDocumentChange(index, e.target.value)
                          }
                        />
                      ) : (
                        <Markdown remarkPlugins={[remarkGfm]}>
                          {document.content}
                        </Markdown>
                      )}

                      {isEditing &&
                        modifiedDocuments.includes(document.document_id) && (
                          <Button
                            variant="contained"
                            onClick={() => saveDocument(document.document_id)}
                            startIcon={
                              loadingDocumentsState.includes(
                                document.document_id
                              ) ? (
                                <CircularProgress size={20} />
                              ) : (
                                <Save />
                              )
                            }
                            disabled={loadingDocumentsState.includes(
                              document.document_id
                            )}
                            style={{ marginTop: "10px" }}
                          >
                            {loadingDocumentsState.includes(
                              document.document_id
                            )
                              ? t("saving")
                              : t("save")}
                          </Button>
                        )}
                    </Box>
                  ))
                ) : (
                  <></>
                )}
                <CenteredButtonContainer>
                  {loadingDocuments ? (
                    <CircularProgress />
                  ) : !allDocumentsLoaded ? (
                    <Button
                      variant="contained"
                      onClick={() => handleScroll()}
                      style={{ marginLeft: "10px" }}
                    >
                      {t("load_more")}
                    </Button>
                  ) : documents.length > 0 ? (
                    <></>
                  ) : (
                    <Typography variant="body1">
                      {t("no_documents_found")}
                    </Typography>
                  )}
                </CenteredButtonContainer>
              </Box>
              <Box
                flex="0 0 10vw"
                pl={2}
                style={{ overflowY: "auto", maxHeight: "75vh" }}
              >
                {Array.from({ length: totalDocumentsLength }).map(
                  (_, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      onClick={() => handleScrollToDocument(index)}
                      fullWidth
                      style={{ marginBottom: "8px" }}
                    >
                      {t("document")} {index + 1}
                    </Button>
                  )
                )}
              </Box>
            </Box>
          </>
        )}
      </Content>

      {/* Device Dialog */}
      <Dialog
        open={openDeviceDialog}
        onClose={() => setOpenDeviceDialog(false)}
      >
        <DialogTitle>{t("add_edit_device")}</DialogTitle>
        <DialogContent>
          <TextField
            label={t("device_id")}
            name="device_id"
            value={deviceForm.device_id}
            onChange={(e) =>
              setDeviceForm({ ...deviceForm, device_id: e.target.value })
            }
            fullWidth
            margin="dense"
          />
          <TextField
            label={t("markdown_content")}
            name="markdown_content"
            value={deviceForm.markdown_content}
            onChange={(e) =>
              setDeviceForm({ ...deviceForm, markdown_content: e.target.value })
            }
            fullWidth
            margin="dense"
          />
          <TextField
            label={t("image_path")}
            name="image_path"
            value={deviceForm.image_path}
            onChange={(e) =>
              setDeviceForm({ ...deviceForm, image_path: e.target.value })
            }
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <AddButton
            onClick={() => setOpenDeviceDialog(false)}
            startIcon={<ArrowBack />}
          >
            {t("cancel")}
          </AddButton>
          <SaveButton
            onClick={handleDeviceSave}
            endIcon={<Save />}
            disabled={!deviceForm.device_id}
          >
            {t("save")}
          </SaveButton>
        </DialogActions>
      </Dialog>

      {/* New Document Dialog */}
      <Dialog
        open={openDocumentDialog}
        onClose={() => handleNewDocumentDialogOpen(false)}
        fullWidth="true"
        maxWidth="md"
      >
        <DialogTitle>{t("add_document")}</DialogTitle>
        <DialogContent>
          {loadingNewDocument ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <StyledTextareaAutosize
                rowsMin={10}
                name="content"
                value={newDocumentForm.content}
                onChange={(e) =>
                  setNewDocumentForm({
                    ...newDocumentForm,
                    content: e.target.value,
                  })
                }
                placeholder={t("enter_document_content")}
              />

              {/* File Upload Section */}
              <Box mt={2}>
                <Button
                  variant="outlined"
                  startIcon={<UploadFile />}
                  component="label"
                >
                  {t("select_file")}
                  <input
                    hidden
                    type="file"
                    name="file"
                    accept=".txt,.pdf,.doc,.docx,.jpg,.jpeg,.png,.zip"
                    onChange={handleFileChange}
                  />
                </Button>

                <Box mt={1} color="text.secondary">
                  {newDocumentForm.file
                    ? newDocumentForm.file.name
                    : t("no_file_selected")}
                </Box>
              </Box>

              {/* Checkboxes for additional options */}
              <Box mt={2} display="flex" flexDirection="column">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newDocumentForm.clear_existing}
                      onChange={(e) =>
                        setNewDocumentForm({
                          ...newDocumentForm,
                          clear_existing: e.target.checked,
                        })
                      }
                    />
                  }
                  label={t("clear_existing")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newDocumentForm.returnable_images}
                      onChange={(e) =>
                        setNewDocumentForm({
                          ...newDocumentForm,
                          returnable_images: e.target.checked,
                        })
                      }
                    />
                  }
                  label={t("returnable_images")}
                />
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handleNewDocumentDialogOpen(false)}
            startIcon={<ArrowBack />}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleNewDocumentSave}
            endIcon={
              loadingNewDocument ? <CircularProgress size={24} /> : <Save />
            }
            disabled={loadingNewDocument}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm delete all documents */}
      <Dialog
        open={openConfirmAllDialog}
        onClose={() => setOpenConfirmAllDialog(false)}
      >
        <DialogTitle>{t("confirm_delete_all_documents")}</DialogTitle>
        <DialogContent>
          <Typography>{t("confirm_delete_all_documents_message")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmAllDialog(false)}
            startIcon={<ArrowBack />}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteAllDocuments}
            startIcon={<Delete />}
          >
            {t("remove_all_documents")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm delete document dialog */}
      <Dialog
        open={openConfirmDeleteDialog}
        onClose={() => setOpenConfirmDeleteDialog(false)}
      >
        <DialogTitle>{t("confirm_delete_document")}</DialogTitle>
        <DialogContent>
          <Typography>{t("confirm_delete_document_message")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmDeleteDialog(false)}
            startIcon={<ArrowBack />}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={confirmDocumentDelete}
            startIcon={<Delete />}
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Root>
  );
};

export default Documents;
