import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#4A90E2", // Light blue
    },
    secondary: {
      main: "#E0E0E0", // Light gray for accents
    },
    background: {
      default: "#f9f9f9", // Even softer light gray background
      paper: "#FFFFFF", // Pure white for cards and containers
    },
    text: {
      primary: "#1A1A1A", // Dark gray for main text (almost black)
      secondary: "#4A4A4A", // Medium gray for secondary text
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontSize: "2.5rem",
      fontWeight: 600,
      color: "#1A1A1A", // Dark gray for better contrast
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
      color: "#4A4A4A", // Medium gray
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#1A1A1A", // Default body text
    },
  },
  shape: {
    borderRadius: 20, // Softly rounded corners
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: "20px", // Rounded borders for the sidebar
          height: "calc(100vh - 64px)", // Adjust height considering AppBar height
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          color: "#1A1A1A",
          borderRadius: 20,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "linear-gradient(145deg, #FFFFFF, #F5F5F7)",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "0 0 20px 20px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Disable uppercase transformation
          borderRadius: 10, // Softer button corners
          padding: "8px 16px", // Consistent padding
        },
      },
    },
  },
});

export default theme;
