import React from "react";
import {
  List,
  ListItemIcon,
  Toolbar,
  Divider,
  IconButton,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  StyledDrawer,
  DrawerContainer,
  StyledListItemText,
  LogoContainer,
  StyledListItem,
} from "./SidebarStyles";
import { menuItems } from "../../utils/Config";
import { hasAccessToRoute } from "../../utils/AuthUtils";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ isCollapsed, toggleSidebar, isMobile }) => {
  const { t } = useTranslation("sidebar");
  const location = useLocation();
  return (
    <StyledDrawer
      variant="permanent"
      open={isCollapsed}
      sx={{
        width: isMobile
          ? isCollapsed
            ? "0"
            : "100vw"
          : isCollapsed
          ? "7vw"
          : "15vw",
        transition: "width 0.3s ease-in-out",
        "& .MuiDrawer-paper": {
          width: isMobile
            ? isCollapsed
              ? "0"
              : "100vw"
            : isCollapsed
            ? "7vw"
            : "15vw",
          transition: "width 0.3s ease-in-out",
          height: "100%",
          borderRadius: 0,
        },
      }}
    >
      <Toolbar>
        <LogoContainer>
          {/* Logo */}
          {!isCollapsed ? (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/manuar-icon.png`}
                alt="Manuar Logo"
                style={{
                  height: "1.5vh",
                  marginRight: "0.5vw",
                }}
              />
              <img
                src={`${process.env.PUBLIC_URL}/manuar-text.png`}
                alt="Manuar Logo"
                style={{
                  height: "1.25vh",
                }}
              />
            </>
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/manuar-icon.png`}
              alt="Manuar Logo"
              style={{
                height: "2vh",
              }}
            />
          )}
        </LogoContainer>
        {/* Collapse Button */}
        <IconButton onClick={toggleSidebar}>
          {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Toolbar>
      <DrawerContainer>
        <List>
          {menuItems
            .filter((item) => hasAccessToRoute(item.section))
            .map((item, index) => {
              const isSelected = location.pathname === item.link; // Check if current route is selected
              return (
                <StyledListItem
                  button
                  onClick={toggleSidebar}
                  component={Link}
                  to={item.link}
                  key={index}
                  className={`sidebar-${item.text.toLowerCase()}`}
                  isSelected={isSelected} // Highlight if selected
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    {item.icon}
                  </ListItemIcon>
                  {!isCollapsed && (
                    <StyledListItemText primary={t(item.text.toLowerCase())} />
                  )}
                </StyledListItem>
              );
            })}
        </List>
        <Divider />
      </DrawerContainer>
    </StyledDrawer>
  );
};

export default Sidebar;
