// File: src/components/Dashboard/DashboardStyles.js
import { styled } from "@mui/material/styles";
import { Paper, Box } from "@mui/material";

export const StyledCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "10px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
}));

export const PlaceholderCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "10px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
  opacity: 1,
}));

export const StyledChartContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "10px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
}));

export const CardHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start", // Align items at the top of the card
  marginBottom: theme.spacing(2),
  textAlign: "left", // Align title text to the left
}));

export const CardIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  "& svg": {
    fontSize: "40px", // Adjust the icon size here
    color: theme.palette.primary.main, // Change this color to match your design
  },
}));

export const CardBody = styled(Box)(({ theme }) => ({
  textAlign: "left", // Align text to the left
  marginTop: theme.spacing(1), // Add margin if needed
  "& span": {
    fontSize: "14px",
  },
}));

export const MonetizationOnIcon = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      opacity="0.21"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
      fill="#8280FF"
    />{" "}
    <path
      d="M30 13C21.1633 13 14 20.3871 14 29.5C14 38.6129 21.1633 46 30 46C38.8367 46 46 38.6129 46 29.5C45.9882 20.3923 38.8317 13.0122 30 13ZM31.1092 36.6808H30.9566V38.5289C30.9566 39.0744 30.529 39.5154 30 39.5154C29.4711 39.5154 29.0435 39.0744 29.0435 38.5289V36.6808H26.9514C26.4224 36.6808 25.9948 36.2398 25.9948 35.6943C25.9948 35.1489 26.4224 34.7079 26.9514 34.7079H31.1094C32.2396 34.7065 33.1554 33.7608 33.1554 32.5952C33.1541 31.431 32.2396 30.4866 31.1094 30.4852H28.8896C26.7028 30.4852 24.929 28.6575 24.929 26.4022C24.929 24.1471 26.7014 22.3179 28.8883 22.3179H28.8896H29.0422V20.4698C29.0422 19.9243 29.4698 19.4833 29.9988 19.4833C30.5277 19.4833 30.9554 19.9243 30.9554 20.4698V22.3179H33.0474C33.5764 22.3179 34.004 22.7589 34.004 23.3043C34.004 23.8498 33.5764 24.2908 33.0474 24.2908L28.8908 24.2922C27.7605 24.2922 26.8435 25.2379 26.8435 26.4035C26.8435 27.5691 27.7606 28.5148 28.8908 28.5148H31.1105C33.2974 28.5162 35.0698 30.3453 35.0684 32.6006C35.0658 34.8531 33.2948 36.6794 31.1092 36.6808Z"
      fill="#8280FF"
    />{" "}
  </svg>
);

export const DevicesIcon = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      opacity="0.21"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
      fill="#FEC53D"
    />{" "}
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 24.3164L27.9005 31.7645C28.0394 31.8447 28.1851 31.9026 28.3333 31.9394V46.3846L15.9201 39.0385C15.3498 38.701 15 38.0875 15 37.4248V24.3164ZM45 24.1184V37.4248C45 38.0875 44.6502 38.701 44.0799 39.0385L31.6667 46.3846V31.8129C31.6969 31.7978 31.7269 31.7816 31.7566 31.7645L45 24.1184Z"
      fill="#FEC53D"
    />{" "}
    <path
      opacity="0.499209"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.4053 20.7014C15.5628 20.5024 15.7617 20.3343 15.9936 20.2108L29.1186 13.2201C29.6696 12.9266 30.3305 12.9266 30.8815 13.2201L44.0065 20.2108C44.1852 20.306 44.3444 20.4277 44.4801 20.5697L30.0899 28.8778C29.9953 28.9325 29.9081 28.995 29.8286 29.064C29.7491 28.995 29.6618 28.9325 29.5672 28.8778L15.4053 20.7014Z"
      fill="#FEC53D"
    />{" "}
  </svg>
);

export const DoneIcon = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      opacity="0.21"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
      fill="#4AD991"
    />{" "}
    <path
      d="M19.1111 40.8889H42.4444C43.3036 40.8889 44 41.5853 44 42.4444C44 43.3036 43.3036 44 42.4444 44H17.5556C16.6964 44 16 43.3036 16 42.4444V17.5556C16 16.6964 16.6964 16 17.5556 16C18.4147 16 19.1111 16.6964 19.1111 17.5556V40.8889Z"
      fill="#4AD991"
    />{" "}
    <path
      opacity="0.5"
      d="M24.9126 34.175C24.325 34.8017 23.3406 34.8335 22.7138 34.2459C22.0871 33.6583 22.0553 32.6739 22.6429 32.0472L28.4762 25.8249C29.0445 25.2188 29.9888 25.1662 30.6208 25.7056L35.2248 29.6343L41.2235 22.0361C41.7558 21.3618 42.734 21.2467 43.4083 21.779C44.0826 22.3114 44.1977 23.2895 43.6653 23.9638L36.6653 32.8305C36.1186 33.5231 35.1059 33.6227 34.4347 33.0499L29.7306 29.0358L24.9126 34.175Z"
      fill="#4AD991"
    />{" "}
  </svg>
);

export const PendingIcon = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
      fill="#FF9066"
    />{" "}
    <path
      opacity="0.78"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.6309 23.8088C28.651 23.5483 28.8682 23.3472 29.1294 23.3472H29.5473C29.8041 23.3472 30.0192 23.5418 30.0448 23.7974L30.6664 30.0138L35.0812 32.5365C35.237 32.6256 35.3331 32.7912 35.3331 32.9707V33.3592C35.3331 33.6889 35.0196 33.9283 34.7015 33.8416L28.3984 32.1226C28.167 32.0595 28.0131 31.841 28.0315 31.6018L28.6309 23.8088Z"
      fill="#FF9066"
    />{" "}
    <path
      opacity="0.901274"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.7218 14.9843C22.4577 14.6696 21.9477 14.7901 21.8524 15.1897L20.2189 22.0379C20.1412 22.3635 20.3993 22.672 20.7336 22.6531L27.7783 22.2539C28.1892 22.2306 28.3976 21.7485 28.133 21.4333L26.3316 19.2864C27.4965 18.8884 28.7317 18.6805 30 18.6805C36.2592 18.6805 41.3333 23.7546 41.3333 30.0138C41.3333 36.273 36.2592 41.3471 30 41.3471C23.7408 41.3471 18.6667 36.273 18.6667 30.0138C18.6667 28.963 18.809 27.9339 19.0864 26.9448L16.5188 26.2245C16.1808 27.4297 16 28.7006 16 30.0138C16 37.7458 22.268 44.0138 30 44.0138C37.732 44.0138 44 37.7458 44 30.0138C44 22.2818 37.732 16.0138 30 16.0138C28.0551 16.0138 26.2029 16.4104 24.5197 17.1271L22.7218 14.9843Z"
      fill="#FF9066"
    />{" "}
  </svg>
);
