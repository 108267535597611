import React from "react";
import { Grid, Box, Paper, IconButton } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import { TextareaAutosize } from "@mui/material";

const ChatInput = ({
  selectedChat,
  sendingMessage,
  handleSendMessage,
  newMessage,
  setNewMessage,
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      style={{
        height: "10%",
      }}
    >
      <>
        <Box
          component={Paper}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexShrink: 0, // Prevent this area from shrinking
          }}
        >
          <TextareaAutosize
            minRows={1}
            maxRows={4}
            placeholder="Escribe un mensaje..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter" && !sendingMessage) {
                handleSendMessage();
              }
            }}
            disabled={!selectedChat || sendingMessage}
            style={{
              width: "100%",
              padding: "8px",
              resize: "none",
              borderRadius: "20px",
              fontFamily: "'Roboto', sans-serif",
              border: "0px solid #ccc",
            }}
          />
          {newMessage.length < 10 && (
            <>
              {/* Image Upload Button */}
              <IconButton
                sx={{ p: "10px" }}
                aria-label="subir imagen"
                disabled={!selectedChat}
              >
                <PhotoCameraIcon />
              </IconButton>

              {/* Microphone Button */}
              <IconButton
                sx={{ p: "10px" }}
                aria-label="micrófono"
                disabled={!selectedChat}
              >
                <MicIcon />
              </IconButton>
            </>
          )}
          {/* Send Message Button */}
          <IconButton
            sx={{ p: "10px" }}
            aria-label="enviar"
            onClick={handleSendMessage}
            disabled={!selectedChat || sendingMessage}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </>
    </Grid>
  );
};

export default ChatInput;
