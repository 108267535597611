import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale, // For the x-axis (category scale)
  LinearScale, // For the y-axis (linear scale)
  PointElement,
  Tooltip,
  Legend,
  Filler, // For filling the area below the line
} from "chart.js";

// Register the components you want to use in Chart.js
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Filler // Allow area fill below the line
);

const ConversationsChart = ({ data, isSidebarOpen }) => {
  const [chartKey, setChartKey] = useState(0); // Key to trigger re-render

  useEffect(() => {
    // Function to handle window resize and sidebar state changes
    const handleResize = () => {
      setChartKey((prevKey) => prevKey + 1); // Increment key to force re-render
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Trigger rerender when sidebar opens or closes
    handleResize(); // Call once initially to adjust to the current sidebar state

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSidebarOpen]); // Add isSidebarOpen as a dependency to rerender when it changes

  const chartData = {
    labels: data.map((d) => d.time), // assuming data has a time field
    datasets: [
      {
        label: "Conversations",
        data: data.map((d) => d.value), // assuming data has a value field
        fill: true,
        backgroundColor: "rgba(0, 123, 255, 0.1)", // Subtle fill for the area
        borderColor: "#4A90E2", // Softer blue for the line
        borderWidth: 2,
        pointBackgroundColor: "#4A90E2", // Matching points with the line color
        pointRadius: 4, // Larger points for emphasis
        pointHoverRadius: 6, // Larger on hover
        pointHoverBackgroundColor: "#4A90E2",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        backgroundColor: "rgba(74, 144, 226, 0.8)", // Rounded tooltips
        bodyColor: "#fff",
        titleColor: "#fff",
        padding: 10,
        cornerRadius: 5,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.raw} Conversations`;
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false, // Remove vertical gridlines
        },
        title: {
          display: false, // Hide the x-axis title
        },
        ticks: {
          color: "#888", // Softer gray for x-axis labels
        },
      },
      y: {
        display: true,
        grid: {
          color: "rgba(200, 200, 200, 0.2)", // Light gridlines
        },
        ticks: {
          beginAtZero: true,
          stepSize: 20, // Set step size for better spacing
          color: "#888", // Softer gray for y-axis labels
        },
        title: {
          display: false, // Hide the y-axis title
        },
      },
    },
  };

  return <Line data={chartData} options={options} key={chartKey} />; // Use the key prop to force re-render
};

export default ConversationsChart;
