import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Select,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CommentIcon from "@mui/icons-material/Comment";
import AddIcon from "@mui/icons-material/Add";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LanguageIcon from "@mui/icons-material/Language";
import { FaDiscord } from "react-icons/fa";
import { UnreadBadge, StyledButton } from "./ChatsViewStyles";
import { getChatTitle } from "../../utils/Chat";
import { getData } from "../../services/Api";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { hasPrivilege } from "../../utils/AuthUtils";

const ITEMS_PER_PAGE = 9; // Number of chats per page

const ChatList = ({
  chats,
  loadingChats,
  unreadMessages,
  selectedChat,
  handleChatClick,
  lastEvaluatedKey,
  handleLoadMore,
  currentPage,
  setCurrentPage,
  selectedState,
  handleStateChange,
  openNewChat,
}) => {
  const { t } = useTranslation("chats");

  const [webhooks, setWebhooks] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const getStatusColor = (state) => {
    switch (state) {
      case "active":
        return "primary"; // Blue
      case "human_required":
        return "warning"; // Purple
      case "resolved_by_user":
        return "success"; // Green
      default:
        return "default"; // Grey
    }
  };

  // Separate human_required chats from others and sort by latest message timestamp
  const humanRequiredChats = chats.filter(
    (chat) => chat.state === "human_required"
  );
  const otherChats = chats.filter((chat) => chat.state !== "human_required");

  const sortedChats = [
    ...humanRequiredChats.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
    ),
    ...otherChats.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
    ),
  ];

  // Paginate sorted chats
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedChats = sortedChats.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (endIndex + 1 < sortedChats.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (lastEvaluatedKey) {
      handleLoadMore();
    }
  };

  const handleBackPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Dialog state for creating a new chat
  const [openDialog, setOpenDialog] = useState(false);
  const [newContactNumber, setNewContactNumber] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const handleOpenDialog = () => {
    setOpenDialog(true);
    fetchWebhooks(); // Load webhooks when the dialog opens
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewContactNumber("");
    setSelectedChannel("");
    setTemplates([]);
  };

  const handleCreateChat = () => {
    openNewChat(newContactNumber, selectedChannel, selectedTemplate); // Pass channel to parent function
    handleCloseDialog();
  };

  const fetchWebhooks = async () => {
    try {
      const response = await getData("/backoffice/webhooks");
      setWebhooks([...response.webhooks]);
    } catch (error) {
      console.error("Error fetching webhooks:", error);
    }
  };

  const fetchTemplates = async (webhookId) => {
    try {
      setLoadingTemplates(true);
      const response = await getData("/backoffice/whatsapp/templates", {
        wp_business_id: webhookId,
      });
      setTemplates(response.templates);
    } catch (error) {
      console.error("Error fetching WhatsApp templates:", error);
    } finally {
      setLoadingTemplates(false);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <List>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <h2 style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
            <CommentIcon style={{ fontSize: "24px", color: "black" }} />
            {t("Conversaciones")}
          </h2>
          {hasPrivilege("backoffice_chat", "edit") && (
            <StyledButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenDialog}
            >
              {!isSmallScreen && t("new_chat")}
            </StyledButton>
          )}
        </Box>

        <FormControl fullWidth size="small">
          <InputLabel>{t("Filtrar por estado")}</InputLabel>
          <Select
            value={selectedState}
            onChange={handleStateChange}
            label={t("Filtrar por estado")}
          >
            <MenuItem value="all">{t("all")}</MenuItem>
            <MenuItem value="active">{t("active")}</MenuItem>
            <MenuItem value="human_required">{t("human_required")}</MenuItem>
            <MenuItem value="stale">{t("stale")}</MenuItem>
            <MenuItem value="resolved_by_user">
              {t("resolved_by_user")}
            </MenuItem>
            <MenuItem value="closed_by_operator">
              {t("closed_by_operator")}
            </MenuItem>
          </Select>
        </FormControl>

        {paginatedChats.length > 0 || !loadingChats ? (
          paginatedChats.map((chat) => {
            const lastMessage = chat.messages[chat.messages.length - 1] || {};
            const lastMessageText =
              lastMessage.sender === "ai"
                ? t("waiting_user_message")
                : lastMessage.message;

            const timestamp = lastMessage.timestamp
              ? new Date(lastMessage.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "";

            const chatName = getChatTitle(chat);
            const stateColor = getStatusColor(chat.state);

            const isDisabled =
              chat.human_id &&
              chat.human_id !==
                JSON.parse(localStorage.getItem("user"))["user_id"];

            return (
              <ListItem
                key={chat.chat_id}
                onClick={() => handleChatClick(chat)}
                selected={selectedChat && selectedChat.chat_id === chat.chat_id}
                sx={{
                  backgroundColor: isDisabled
                    ? "grey"
                    : unreadMessages[chat.chat_id]?.hasNewMessages
                    ? "#e3f2fd"
                    : "inherit",
                  padding: 2,
                  cursor: isDisabled ? "not-allowed" : "pointer",
                  "&:hover": {
                    backgroundColor: isDisabled ? "inherit" : "action.hover",
                  },
                  position: "relative",
                }}
              >
                <Chip
                  label={t(chat.state)}
                  color={stateColor}
                  size="small"
                  sx={{ position: "absolute", top: 8, right: 8 }}
                />

                <ListItemText
                  primary={chatName}
                  secondary={
                    <>{`${timestamp}${
                      lastMessageText
                        ? ": " + lastMessageText.slice(0, 50) + "..."
                        : ""
                    }`}</>
                  }
                />
                {unreadMessages[chat.chat_id]?.hasNewMessages && (
                  <UnreadBadge>!</UnreadBadge>
                )}
              </ListItem>
            );
          })
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </List>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: "1vh", gap: "1vw" }}
      >
        <Button
          variant="contained"
          onClick={handleBackPage}
          disabled={currentPage === 1}
        >
          {t("previous")}
        </Button>
        <Typography>{currentPage}</Typography>
        <Button
          variant="contained"
          onClick={handleNextPage}
          disabled={
            loadingChats ||
            (endIndex >= sortedChats.length && !lastEvaluatedKey)
          }
        >
          {loadingChats ? <CircularProgress /> : t("next")}
        </Button>
      </Box>

      {/* New Chat Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            overflow: "visible", // Allow content to overflow
          },
        }}
        TransitionProps={{
          onEntering: () => {
            const dropdown = document.querySelector(
              ".react-tel-input .country-list"
            );
            if (dropdown) {
              dropdown.style.position = "absolute"; // Ensure dropdown expands outside the dialog
              dropdown.style.zIndex = 1300; // Ensure it appears above the dialog
            }
          },
        }}
      >
        <DialogTitle>{t("new_chat")}</DialogTitle>
        <DialogContent>
          <PhoneInput
            country={"uy"}
            value={newContactNumber}
            onChange={setNewContactNumber}
            inputStyle={{
              width: "100%",
              height: "40px",
            }}
            dropdownStyle={{
              zIndex: 1350,
              position: "absolute",
            }}
            containerStyle={{
              marginBottom: "1rem",
            }}
            enableSearch
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("communication_channel")}</InputLabel>
            <Select
              value={selectedChannel}
              onChange={(e) => {
                const selectedId = e.target.value;
                setSelectedChannel(selectedId);
                if (selectedId.startsWith("whatsapp")) {
                  fetchTemplates(selectedId.split("-")[1]); // Fetch templates for WhatsApp
                } else {
                  setTemplates([]); // Clear templates if not WhatsApp
                }
              }}
              label={t("communication_channel")}
            >
              {webhooks.map((webhook) => {
                const { webhook_id } = webhook;

                // Determine the prefix, ID, and corresponding icon
                const [prefix, id] = webhook_id.split("-");
                let IconComponent;
                let label;

                if (prefix === "whatsapp") {
                  IconComponent = WhatsAppIcon;
                  label = t("WhatsApp");
                } else if (prefix === "discord") {
                  IconComponent = FaDiscord;
                  label = t("Discord");
                } else if (prefix === "web") {
                  IconComponent = LanguageIcon;
                  label = t("Web");
                }

                return (
                  <MenuItem key={webhook_id} value={webhook_id}>
                    {IconComponent && (
                      <IconComponent style={{ marginRight: 8 }} />
                    )}
                    {`${label} (${id})`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {selectedChannel.startsWith("whatsapp") && templates.length > 0 && (
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel>{t("select_template")}</InputLabel>
                <Select
                  value={selectedTemplate}
                  onChange={(e) => {
                    setSelectedTemplate(e.target.value);
                  }}
                  label={t("select_template")}
                >
                  {templates.map((template) => (
                    <MenuItem key={template.name} value={template.name}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Preview the selected template */}
              {selectedTemplate && (
                <Box
                  mt={2}
                  p={2}
                  border="1px solid #ccc"
                  borderRadius="4px"
                  bgcolor="#f9f9f9"
                >
                  {templates
                    .find((template) => template.name === selectedTemplate)
                    ?.components.map((component, index) => (
                      <Typography
                        key={index}
                        variant={
                          component.type === "HEADER"
                            ? "h5"
                            : component.type === "FOOTER"
                            ? "subtitle2"
                            : "body1"
                        }
                        gutterBottom
                      >
                        {component.text}
                      </Typography>
                    ))}
                </Box>
              )}
            </>
          )}

          {loadingTemplates && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t("cancel")}</Button>
          <Button
            variant="contained"
            onClick={handleCreateChat}
            disabled={!newContactNumber}
          >
            {t("start_chat")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChatList;
