import React, { useState, useEffect } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { postData } from "../../services/Api"; // Import the postData function
import { Root, StyledPaper, Title, StyledButton } from "./LoginStyles"; // Import styled components
import { isAuthenticated } from "../../utils/AuthUtils"; // Import authentication utility

const Login = () => {
  const { t } = useTranslation("login"); // Initialize translation hook
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Add state for error message
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object to access query parameters

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("demo")) {
      // Automatically fill in the demo credentials if the demo query param is present
      setCompany("demo");
      setEmail("demo@manuar.ai");
      setPassword("manuar");

      // Automatically trigger the login
      handleLogin();
    }
  }, [location.search]); // Run the effect when the location.search changes

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("demo") && company && email && password) {
      handleLogin();
    }
  }, [company, email, password]); // Run the effect when the location.search changes

  const handleLogin = async () => {
    try {
      const data = {
        email: email.trim(),
        password: password,
        company: company.trim(),
      };

      const response = await postData("/login_signup", data);

      if (response.privilege && response.tokens && response.user_id) {
        const userWithCompany = { ...response, company };
        localStorage.setItem("user", JSON.stringify(userWithCompany));
        navigate("/dashboard");
      } else {
        setErrorMessage(t("Login.error.general_failure")); // Set error message using translation
      }
    } catch (error) {
      setErrorMessage(t("Login.error.general_failure")); // Set error message using translation
    }
  };

  return isAuthenticated() ? (
    <Navigate to="/dashboard" />
  ) : (
    <Root>
      <StyledPaper>
        <Title variant="h5">{t("login_title")}</Title>
        {errorMessage && (
          <Typography color="error" variant="body2" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        <TextField
          label={t("company_name")}
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          label={t("email")}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          label={t("password")}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <StyledButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
        >
          {t("login")}
        </StyledButton>
      </StyledPaper>
    </Root>
  );
};

export default Login;
