import React, { useState } from "react";
import { useMediaQuery, Grid } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { LayoutContainer, ContentArea } from "./LayoutStyles";

const Layout = ({ children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Sidebar is initially open

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle open/close state
  };

  // Clone children and pass isSidebarOpen as prop
  const childrenWithSidebarProp = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isSidebarOpen });
    }
    return child;
  });

  return (
    <LayoutContainer isSidebarOpen={isSidebarOpen} isMobile={isMobile}>
      <Grid container>
        {/* Navbar section */}
        <Grid item xs={12}>
          <Navbar isMobile={isMobile} toggleSidebar={toggleSidebar} />
        </Grid>

        {/* Sidebar section */}
        <Grid item xs={isSidebarOpen ? 2 : isMobile ? 0 : 1}>
          <Sidebar
            isCollapsed={!isSidebarOpen}
            isMobile={isMobile}
            toggleSidebar={toggleSidebar}
          />
        </Grid>

        {/* Content Area section */}
        <Grid item xs={isSidebarOpen ? 10 : isMobile ? 12 : 11}>
          <ContentArea isSidebarOpen={isSidebarOpen} isMobile={isMobile}>
            {childrenWithSidebarProp}
          </ContentArea>
        </Grid>
      </Grid>
    </LayoutContainer>
  );
};

export default Layout;
