import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import {
  ChatHistory,
  ChatBubbleUser,
  ChatBubbleOperator,
} from "./ChatsViewStyles";

const decodeMessage = (message) => {
  return message
    .replace(/\n/g, "  \n") // Convert escaped newlines to Markdown line breaks
    .replace(/\\n/g, "  \n") // Convert escaped newlines to Markdown line breaks
    .replace(/\\u([\dA-F]{4})/gi, (_, code) =>
      String.fromCharCode(parseInt(code, 16))
    ); // Decode unicode sequences
};

const ChatMessages = ({ messages, chatHistoryRef, isMobile }) => {
  const { t } = useTranslation("chats");
  const [openContextDialog, setOpenContextDialog] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = useState(0);

  // Este efecto asegura que el chat haga scroll hacia el final al cargar o actualizar los mensajes
  useEffect(() => {
    if (chatHistoryRef?.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  const handleOpenContextDialog = (message) => {
    setSelectedMessage(message);
    setOpenContextDialog(true);
  };

  const handleCloseContextDialog = () => {
    setOpenContextDialog(false);
    setSelectedMessage(null);
  };

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? index : false);
  };

  return (
    <ChatHistory ref={chatHistoryRef} isMobile={isMobile}>
      {messages
        .filter((message) => message.sender !== "ai" || message.complete)
        .flatMap((message) => {
          if (message.message && message.message.includes("[new_message]")) {
            return message.message
              .split("[new_message]")
              .map((text, index) => ({
                ...message,
                message: text,
                timestamp: message.timestamp + `_${index}`,
              }));
          }
          return [message];
        })
        .map((message, index) => {
          const isOperator = message.sender.startsWith("operator");
          const operatorEmail = isOperator
            ? message.sender.split("-").slice(1).join("-")
            : "";
          const Component =
            isOperator || message.sender === "ai"
              ? ChatBubbleOperator
              : ChatBubbleUser;

          let messageText = message.message;
          if (
            message.sender === "ai" &&
            messageText &&
            messageText.includes("[delete]")
          ) {
            const parts = messageText.split("[delete]");
            messageText = parts.length > 1 ? parts[1].trim() : parts[0].trim();
          }

          return (
            <Component key={index}>
              {isOperator && (
                <Typography variant="caption" fontWeight="bold" mb={0.5}>
                  {operatorEmail}
                </Typography>
              )}
              {message.attachments &&
                message.attachments.map((attachment, idx) => (
                  <Box key={idx} mt={1}>
                    {attachment.type === "image" && (
                      <img
                        src={`data:image/jpeg;base64,${attachment.path}`}
                        alt="attachment"
                        style={{ maxWidth: "100%", borderRadius: "8px" }}
                      />
                    )}
                    {attachment.type === "audio" && (
                      <Box mt={1}>
                        <audio
                          controls
                          src={`data:audio/ogg;base64,${attachment.path}`}
                        >
                          Your browser does not support the audio element.
                        </audio>
                        <p>
                          <strong>Transcription:</strong>
                        </p>
                      </Box>
                    )}
                  </Box>
                ))}{" "}
              {messageText && (
                <ReactMarkdown remarkPlugins={[remarkGfm]} breaks={true}>
                  {decodeMessage(messageText)}
                </ReactMarkdown>
              )}
              <Typography variant="caption" color="textSecondary">
                {new Date(message.timestamp.split("_")[0]).toLocaleString()}
              </Typography>
              {message.context_info && (
                <Tooltip title={t("view_message_info")}>
                  <IconButton onClick={() => handleOpenContextDialog(message)}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Component>
          );
        })}

      {/* Dialog to compare original message and context info */}
      <Dialog
        open={openContextDialog}
        onClose={handleCloseContextDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{t("context_information")}</DialogTitle>
        <DialogContent>
          {selectedMessage && (
            <Grid container spacing={2}>
              {/* Original message */}
              <Grid item xs={6}>
                <Typography variant="h6">{t("original_message")}</Typography>
                <Paper
                  elevation={1}
                  style={{ padding: "10px", wordBreak: "break-word" }}
                >
                  <ReactMarkdown remarkPlugins={[remarkGfm]} breaks={true}>
                    {decodeMessage(selectedMessage.message)}
                  </ReactMarkdown>
                </Paper>
              </Grid>

              {/* Context information */}
              <Grid item xs={6}>
                <Typography variant="h6">{t("context_info")}</Typography>
                {selectedMessage.context_info
                  .filter((contextItem) =>
                    Object.values(contextItem).some(
                      (value) => String(value).trim() !== ""
                    )
                  )
                  .map((contextItem, index) => (
                    <Accordion
                      expanded={expandedAccordion === index}
                      onChange={handleAccordionChange(index)}
                      key={index}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{`${t("Context Info")} ${
                          index + 1
                        }`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {Object.entries(contextItem)
                          .filter(([key, value]) => String(value).trim() !== "")
                          .map(([key, value], idx) => (
                            <Box key={idx} mb={2}>
                              <Typography variant="body2" fontWeight="bold">
                                {t(key)}:
                              </Typography>
                              <Paper
                                elevation={1}
                                style={{
                                  padding: "10px",
                                  wordBreak: "break-word",
                                }}
                              >
                                <ReactMarkdown
                                  remarkPlugins={[remarkGfm]}
                                  breaks={true}
                                >
                                  {decodeMessage(String(value))}
                                </ReactMarkdown>
                              </Paper>
                            </Box>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <Button
          onClick={handleCloseContextDialog}
          variant="contained"
          style={{ margin: "20px" }}
        >
          {t("close")}
        </Button>
      </Dialog>
    </ChatHistory>
  );
};

export default ChatMessages;
