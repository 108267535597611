import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const LayoutContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh", // Ensure the layout takes full height
  backgroundColor: theme.palette.background.default,
  transition: "grid-template-columns 0.3s ease-in-out", // Smooth transition for grid columns
}));

export const ContentArea = styled(Box)(
  ({ theme, isSidebarOpen, isMobile }) => ({
    marginTop: isMobile ? theme.spacing(6) : theme.spacing(4),
    padding: isMobile ? theme.spacing(1) : theme.spacing(3),
    transition: isMobile ? "transform 0.3s ease-in-out" : "none", // Smooth transition only on mobile
    transform:
      isSidebarOpen && isMobile ? "translateX(-240px)" : "translateX(0)", // Shift content only on mobile
  })
);
