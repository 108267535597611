import { parsePhoneNumberFromString } from "libphonenumber-js"; // Import libphonenumber-js

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";

export const getCountryFlagAndUserId = (userId) => {
  let countryFlag = "🏳️";
  let cleanedUserId = userId;
  const phoneNumberObject = parsePhoneNumberFromString(`+${userId}`, "");
  if (phoneNumberObject && phoneNumberObject.country) {
    countryFlag = phoneNumberObject.country;
    cleanedUserId = userId
      .replace(phoneNumberObject.countryCallingCode, "")
      .replace(/^\s+|\s+$/g, "");
  }
  return countryFlag + " " + cleanedUserId;
};

export const getChatTitle = (chat) => {
  const chat_info = chat.user_id.split("~");
  const chat_origin = chat_info[0];
  return chat.chat_id.startsWith("whatsapp:") ? (
    <>
      <WhatsAppIcon color="primary" />
      {chat.chat_name}
      <br />
      {getCountryFlagAndUserId(chat.user_id.split(":")[1])}
    </>
  ) : chat_origin === "unknown" ? (
    <>
      <PersonOffIcon color="action" /> {chat_info[chat_info.length - 1]}
    </>
  ) : (
    <>
      <PersonIcon color="primary" /> {chat_info[chat_info.length - 1]}
    </>
  );
};
