import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import { getData } from "../../services/Api";
import { useTranslation } from "react-i18next";
import {
  StyledCard,
  StyledChartContainer,
  PlaceholderCard,
  CardHeader,
  CardIcon,
  CardBody,
  MonetizationOnIcon,
  DevicesIcon,
  DoneIcon,
  PendingIcon,
} from "./DashboardStyles";
import ConversationsChart from "./ConversationsChart"; // Assuming a component for the chart

const Dashboard = ({ isSidebarOpen }) => {
  const { t } = useTranslation("dashboard");
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const data = await getData("/backoffice/clients/");
        setClientData(data);
      } catch (err) {
        setError(t("error_loading_data"));
      } finally {
        setLoading(false);
      }
    };

    fetchClientData();
  }, [t]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="body1" color="error">
        {error}
      </Typography>
    );
  }

  // Placeholder message for empty data
  const placeholderMessage = t("data_placeholder_message");

  // Fake data points for the chart
  const generateFakeChartData = () => {
    const today = new Date();
    const fakeChartData = [];

    for (let i = 13; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);

      fakeChartData.push({
        time: date.toISOString().split("T")[0], // Format date as YYYY-MM-DD
        value: Math.floor(Math.random() * 100), // Generate a random value between 0 and 100
      });
    }

    return fakeChartData;
  };

  const fakeChartData = generateFakeChartData();

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t("dashboard")}
      </Typography>
      <Grid container spacing={3}>
        {/* Credits Used */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardHeader>
              <Typography variant="subtitle1">{t("credits_used")}</Typography>
              <CardIcon>{MonetizationOnIcon}</CardIcon>
            </CardHeader>
            <CardBody>
              <Typography variant="h4">{clientData.used_credits}</Typography>
              <Typography variant="caption">
                1000 {t("credits_limit")}
              </Typography>
            </CardBody>
          </StyledCard>
        </Grid>

        {/* Devices Entered */}
        <Grid item xs={12} sm={6} md={3}>
          {clientData.devices_entered ? (
            <StyledCard>
              <CardHeader>
                <Typography variant="subtitle1">
                  {t("devices_entered")}
                </Typography>
                <CardIcon>{DevicesIcon}</CardIcon>
              </CardHeader>
              <CardBody>
                <Typography variant="h4">
                  {clientData.devices_entered}
                </Typography>
              </CardBody>
            </StyledCard>
          ) : (
            <PlaceholderCard>
              <CardHeader>
                <Typography variant="subtitle1" style={{ opacity: 0.5 }}>
                  {t("devices_entered")}
                </Typography>
                <CardIcon>{DevicesIcon}</CardIcon>
              </CardHeader>
              <CardBody>
                <Typography variant="h4" style={{ opacity: 0.5 }}>
                  0
                </Typography>
                <Typography variant="caption">{placeholderMessage}</Typography>
              </CardBody>
            </PlaceholderCard>
          )}
        </Grid>

        {/* Conversations Resolved */}
        <Grid item xs={12} sm={6} md={3}>
          {clientData.conversations_resolved ? (
            <StyledCard>
              <CardHeader>
                <Typography variant="subtitle1">
                  {t("conversations_resolved")}
                </Typography>
                <CardIcon>{DoneIcon}</CardIcon>
              </CardHeader>
              <CardBody>
                <Typography variant="h4">
                  {clientData.conversations_resolved}
                </Typography>
              </CardBody>
            </StyledCard>
          ) : (
            <PlaceholderCard>
              <CardHeader>
                <Typography variant="subtitle1" style={{ opacity: 0.5 }}>
                  {t("conversations_resolved")}
                </Typography>
                <CardIcon>{DoneIcon}</CardIcon>
              </CardHeader>
              <CardBody>
                <Typography variant="h4" style={{ opacity: 0.5 }}>
                  0
                </Typography>
                <Typography variant="caption">{placeholderMessage}</Typography>
              </CardBody>
            </PlaceholderCard>
          )}
        </Grid>

        {/* Conversations Pending */}
        <Grid item xs={12} sm={6} md={3}>
          {clientData.conversations_pending ? (
            <StyledCard>
              <CardHeader>
                <Typography variant="subtitle1">
                  {t("conversations_pending")}
                </Typography>
                <CardIcon>{PendingIcon}</CardIcon>
              </CardHeader>
              <CardBody>
                <Typography variant="h4">
                  {clientData.conversations_pending}
                </Typography>
              </CardBody>
            </StyledCard>
          ) : (
            <PlaceholderCard>
              <CardHeader>
                <Typography variant="subtitle1" style={{ opacity: 0.5 }}>
                  {t("conversations_pending")}
                </Typography>
                <CardIcon>{PendingIcon}</CardIcon>
              </CardHeader>
              <CardBody>
                <Typography variant="h4" style={{ opacity: 0.5 }}>
                  0
                </Typography>
                <Typography variant="caption">{placeholderMessage}</Typography>
              </CardBody>
            </PlaceholderCard>
          )}
        </Grid>

        {/* Conversations Chart */}
        <Grid item xs={12}>
          {clientData.conversations_data &&
          clientData.conversations_data.length > 0 ? (
            <StyledChartContainer>
              <Typography variant="h6">{t("conversations")}</Typography>
              <ConversationsChart data={clientData.conversations_data} />
            </StyledChartContainer>
          ) : (
            <StyledChartContainer style={{ opacity: 0.9 }}>
              <Typography variant="h6" style={{ opacity: 0.5 }}>
                {t("conversations")}
              </Typography>
              <div style={{ opacity: 0.2 }}>
                <ConversationsChart
                  data={fakeChartData}
                  isSidebarOpen={isSidebarOpen}
                />
              </div>
              <center>
                <Typography variant="body2" style={{ opacity: 1 }}>
                  {placeholderMessage}
                </Typography>
              </center>
            </StyledChartContainer>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
