import { styled } from "@mui/material/styles";
import { Box, Paper, Button, Typography } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
  padding: theme.spacing(4),
  marginTop: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3),
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.getContrastText(theme.palette.primary.light),
}));
