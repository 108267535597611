import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1, // Lower z-index than sidebar
  background: theme.palette.background.paper,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for modern look
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.text.primary,
}));

export const UserSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  "& > *:not(:last-child)": {
    marginRight: theme.spacing(2), // Increase spacing between navbar items
  },
}));

export const CompanyName = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

export const UserButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const MenuPaper = styled(Menu)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  marginTop: theme.spacing(1),
}));

export const MenuHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1, 2),
}));

export const UserName = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const MenuItemText = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.primary,
  flexGrow: 1,
  "& .MuiListItemIcon-root": {
    minWidth: "auto",
    marginRight: theme.spacing(1),
  },
}));

export const LogoutMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.light),
  flexGrow: 1,
}));

export const LanguageSelectContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));
