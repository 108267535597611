import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Toolbar,
  Divider,
  MenuItem,
  ListItemIcon,
  Button,
  Select,
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  AccountCircle,
  Close,
  ExitToApp,
  Edit as EditIcon,
  Delete as RemoveIcon,
  Visibility as ViewIcon,
  Add as AddIcon,
  Notifications as NotificationsIcon,
  WhatsApp as WhatsAppIcon,
  Delete as TrashIcon,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { format } from "date-fns";
import { getData, patchData, deleteData } from "../../services/Api";
import {
  StyledAppBar,
  UserSection,
  CompanyName,
  UserButton,
  MenuPaper,
  MenuHeader,
  UserName,
  CloseButton,
  MenuItemText,
  LogoutMenuItem,
  LanguageSelectContainer,
} from "./NavbarStyles";
import { menuItems } from "../../utils/Config";
import notificationSound from "../../assets/sounds/notification.wav";
import { getCountryFlagAndUserId } from "../../utils/Chat";

const Navbar = ({ isMobile, toggleSidebar }) => {
  const [userInfo, setUserInfo] = useState({
    user_id: "",
    company: "",
    privileges: {},
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [anchorElNotif, setAnchorElNotif] = useState(null);
  const { t, i18n } = useTranslation("navbar");
  const navigate = useNavigate();
  const audioRef = useRef(new Audio(notificationSound));
  const intervalRef = useRef(null);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission !== "granted") {
          console.warn("Desktop notifications permission not granted");
        }
      });
    }
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserInfo({
        user_id: parsedUser.user_id,
        company: parsedUser.company,
        privileges: parsedUser.privilege,
      });
    }
    fetchNotifications();
  }, []);

  const getNotificationMessage = (notification, t) => {
    let chat_id = notification.metadata?.chat_id;
    if (
      notification.metadata?.chat_id &&
      notification.metadata.chat_id.startsWith("whatsapp:")
    ) {
      chat_id = getCountryFlagAndUserId(
        notification.metadata.chat_id.split("-")[
          notification.metadata.chat_id.split("-").length - 1
        ]
      );
    }
    return notification.type === "chat_requires_assistance"
      ? t("chat_requires_assistance", { chat_id })
      : notification.message || t("new_notification");
  };

  const fetchNotifications = async () => {
    clearTimeout(intervalRef.current);
    let newNotifications = 0;

    try {
      const response = await getData("/backoffice/notifications");
      newNotifications = response.length;

      let prevTimestamp = localStorage.getItem("latestTimestamp")
        ? Number(localStorage.getItem("latestTimestamp"))
        : 0;

      const latestTimestamp = response.reduce(
        (max, notification) =>
          Math.max(max, new Date(notification.timestamp).getTime()),
        prevTimestamp
      );
      if (latestTimestamp > prevTimestamp) {
        localStorage.setItem("latestTimestamp", latestTimestamp);
        audioRef.current.play();

        const unseenNotifications = response.filter(
          (n) => !n.seen && new Date(n.timestamp).getTime() > prevTimestamp
        );

        if (Notification.permission === "granted") {
          if (unseenNotifications.length <= 3) {
            unseenNotifications.forEach((notification) => {
              const notificationMessage = getNotificationMessage(
                notification,
                t
              );

              const desktopNotification = new Notification(
                t("new_notification"),
                {
                  body: notificationMessage,
                  icon: "https://panel.manuar.ai/manuar-icon.png",
                }
              );

              desktopNotification.onclick = async () => {
                await patchData(
                  `/backoffice/notifications/${notification.id}`,
                  { delete: true }
                );
                if (
                  notification.type === "chat_requires_assistance" &&
                  notification.metadata?.chat_id
                ) {
                  window.open(
                    `/chats/${notification.metadata.user_id}/${notification.metadata.chat_id}`,
                    "_blank"
                  );
                } else {
                  window.open("/chats/", "_blank");
                }
                desktopNotification.close();
              };
            });
          } else {
            const desktopNotification = new Notification(
              t("new_notification"),
              {
                body: `${unseenNotifications.length} ${t("new_notification")}`,
                icon: "https://panel.manuar.ai/manuar-icon.png",
              }
            );
            desktopNotification.onclick = () => {
              window.open("/chats/", "_blank");
              desktopNotification.close();
            };
          }
        }
      }
      setNotifications([...response]);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }

    intervalRef.current = setTimeout(fetchNotifications, 1500);
  };

  // Remaining code for Navbar component...
  const handleNotificationMenuOpen = (event) => {
    setAnchorElNotif(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setAnchorElNotif(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  const deleteNotification = async (notification) => {
    const { id, metadata, type } = notification;
    try {
      await patchData(`/backoffice/notifications/${id}`, {
        delete: true,
      });

      if (type === "chat_requires_assistance" && metadata?.chat_id) {
        navigate(`/chats/${metadata.user_id}/${metadata.chat_id}`);
      }

      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n.id !== id)
      );
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const clearAllNotifications = async () => {
    try {
      await deleteData("/backoffice/notifications");
      setNotifications([]);
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
  };

  const unseenCount = notifications.filter((n) => !n.seen).length;

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        {userInfo.company === "demo" && (
          <Button
            variant="contained"
            color="success"
            href="https://wa.me/59891613592"
            sx={{ mx: "auto", marginRight: "1vw" }}
            startIcon={<WhatsAppIcon />}
          >
            {t("write_to_whatsapp")}
          </Button>
        )}
        {isMobile && (
          <IconButton color="black" onClick={toggleSidebar}>
            <MenuIcon />
          </IconButton>
        )}
        <UserSection>
          {/* Notification Icon */}
          <IconButton color="black" onClick={handleNotificationMenuOpen}>
            <Badge badgeContent={unseenCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <MenuPaper
            anchorEl={anchorElNotif}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            open={Boolean(anchorElNotif)}
            onClose={handleNotificationMenuClose}
          >
            {notifications.length > 0 && (
              <center>
                <Button onClick={clearAllNotifications} color="error">
                  <TrashIcon />
                  {t("clear_all")}
                </Button>
              </center>
            )}

            <List>
              {notifications.length > 0 ? (
                notifications.map((notification) => {
                  let chat_id = notification.metadata?.chat_id;
                  if (notification.metadata?.chat_id.startsWith("whatsapp:")) {
                    chat_id = getCountryFlagAndUserId(
                      notification.metadata?.chat_id.split("-")[
                        notification.metadata?.chat_id.split("-").length - 1
                      ]
                    );
                  }
                  return (
                    <ListItem
                      key={notification.id}
                      button
                      onClick={() => deleteNotification(notification)}
                      sx={{
                        backgroundColor: notification.seen
                          ? "#f0f0f0"
                          : "#ffffff",
                      }} // Different background for seen/unseen notifications
                    >
                      <ListItemText
                        primary={
                          notification.type === "chat_requires_assistance"
                            ? t("chat_requires_assistance", {
                                chat_id,
                              })
                            : notification.message
                        }
                        secondary={format(
                          new Date(notification.timestamp),
                          "PPpp" // Formats the timestamp into a readable format
                        )}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <ListItem>
                  <ListItemText primary={t("no_notifications")} />
                </ListItem>
              )}
            </List>
          </MenuPaper>

          <LanguageSelectContainer>
            <Select
              value={i18n.language}
              onChange={handleLanguageChange}
              variant="outlined"
              size="small"
              fullWidth
              className="language-selector"
              sx={{ marginRight: 2 }}
            >
              <MenuItem value="en">
                <ReactCountryFlag
                  countryCode="US"
                  svg
                  style={{ marginRight: 8 }}
                />
                {t("language_english")}
              </MenuItem>
              <MenuItem value="es">
                <ReactCountryFlag
                  countryCode="ES"
                  svg
                  style={{ marginRight: 8 }}
                />
                {t("language_spanish")}
              </MenuItem>
              <MenuItem value="pt">
                <ReactCountryFlag
                  countryCode="BR"
                  svg
                  style={{ marginRight: 8 }}
                />
                {t("language_portuguese")}
              </MenuItem>
            </Select>
          </LanguageSelectContainer>

          <CompanyName variant="body1">{userInfo.company}</CompanyName>
          <UserButton
            aria-controls="privileges-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            className="profile-button"
          >
            <AccountCircle />
          </UserButton>
          <MenuPaper
            id="privileges-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuHeader>
              <UserName variant="subtitle1">{userInfo.user_id}</UserName>
              <CloseButton size="small" onClick={handleMenuClose}>
                <Close />
              </CloseButton>
            </MenuHeader>
            <Divider />
            {menuItems.map(({ section, text, icon }) => {
              const privileges = userInfo.privileges[section] || [];
              if (privileges.length > 0) {
                return (
                  <MenuItem key={section}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <MenuItemText>{t(text.toLowerCase())}</MenuItemText>
                    {privileges.map((privilege, index) => (
                      <ListItemIcon key={index}>
                        {privilege === "view" && <ViewIcon />}
                        {privilege === "create" && <AddIcon />}
                        {privilege === "edit" && <EditIcon />}
                        {privilege === "remove" && <RemoveIcon />}
                      </ListItemIcon>
                    ))}
                  </MenuItem>
                );
              }
              return null;
            })}
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <LogoutMenuItem>{t("logout")}</LogoutMenuItem>
            </MenuItem>
          </MenuPaper>
        </UserSection>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
